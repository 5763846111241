import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { mutateKey } from '@/helpers'

export default function Setup() {
  const store = useStore();

  const loaded = ref(false);  
  const checking = ref(store.state.checkingChosen);

  onMounted(async () => {
    return store.dispatch("getChecking", checking.value.Id)
    .then((res) => {
      checking.value = res;
      mutateKey('checkingChosen', res);
    })
    .then(() => { return loaded.value = true })
  });

  return {
    checking,
    loaded,
  }
}
