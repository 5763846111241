<template>
  <div
    class="mt-0 p-0 p-sm-3 pt-0"
    :class="['lg', 'xl', 'xxl'].includes($store.state.app.windowSize) ? 'w-75 m-auto' : ''"
  >
    <div
      v-if="loaded"
      class="d-flex flex-row justify-content-center align-items-start flex-wrap"
    >
      <div class="custom-size w-100 m-2">
        <CardContainer class="bg-primary w-100 ms-auto">
          <button
            role="link"
            class="position-absolute top-0 end-0 btn btn-primary rounded-3 d-flex flex-row align-items-center mt-n2 me-n1 py-1 px-3 w-fit fw-bold"
            @click="$router.push({ name: 'CheckingUpdate' })"
          >
            <span>
              {{ $tcust('CheckingView.modifyButton', null, true) }}
            </span>
          </button>

          <div class="bg-box d-flex flex-column text-nowrap w-100 p-3 px-4">
            <div class="text-muted fst-italic">
              {{ $tcust("checking.check") }}
            </div>
            <div class="custom-text text-center fs-4 fw-bold mb-1">
              {{ $tcust(checking.checkingType ? 'checking.out' : 'checking.in', false, false, 'upper') }}
            </div>
          </div>
        </CardContainer>

        <!-- EDL infos -->
        <CardContainer class="bg-primary w-100 mt-3 ms-auto">
          <div class="bg-box d-flex flex-column w-100 p-3 px-4 text-center">
            <div class="text-muted fst-italic align-self-start">
              {{ $tcust("CheckingView.appointment") }}
            </div>
            <div
              class="m-auto w-fit fs-5 mt-1 mb-3"
              v-html="`${$tcust('functions.util', { fct: 'dateTimeFormater', params: checking.AppointmentDateTime })}`"
            />

            <hr class="text-primary w-75 mx-auto mt-0 mb-2">

            <div class="flex-grow-1 px-3 py-2 fw-bold w-100">
              <span v-html="$tcust('checking.state', { 'state': checking.State })" />
              &nbsp;-&nbsp;
              <span v-html="$tcust('checking.status', { 'status': checking.Status })" />
            </div>
            <button
              role="link"
              class="btn btn-primary fw-bold text-dark rounded-3 d-flex flex-row align-items-center m-auto py-1 px-3 w-fit"
              @click="$router.push({ name: 'Paiement' })"
            >
              <span>
                {{ $tcust('CheckingView.changeLicenseButton.' + (checking.Status === 0 ? 'toPro' : 'change')) }}
              </span>
              <MDBIcon
                icon="angle-right"
                icon-style="fas"
                size="lg"
                class="ms-2 me-n1"
              />
            </button>
          </div>
        </CardContainer>

        <!-- Comment -->
        <CardContainer
          v-if="checking.Comments"
          class="bg-primary w-100 mt-3 ms-auto"
          :class="!['xs', 'sm'].includes($store.state.app.windowSize) ? 'mw-75 h-fit' : ''"
        >
          <div class="bg-box d-flex flex-column w-100 p-2 px-md-3">
            <div class="m-2">
              <div class="fst-italic text-muted">
                {{ $tcust('checking.comment') }}
              </div>
              <div class="ms-3">
                {{ checking.Comments }}
              </div>
            </div>
          </div>
        </CardContainer>

        <!-- Annexes -->
        <CardContainer
          class="bg-primary w-100 mt-3 ms-auto"
        >
          <div class="bg-box d-flex flex-column w-100 p-2 px-md-3">
            <div class="m-2">
              <div class="fst-italic text-muted">
                {{ $tcust('CheckingView.roomsCountTitle') }}
              </div>
              <div class="my-2 ps-3 text-start">
                <span :class="!checking.Rooms.length ? 'fst-italic text-muted' : ''">
                  {{ $tcust('checking.roomsCount', { n: checking.Rooms.length }) }}
                </span>
              </div>
            </div>

            <hr class="text-primary w-75 m-auto mb-3">

            <div class="m-2 mt-0">
              <div class="fst-italic text-muted">
                {{ $tcust('checking.annexes.title') }}
              </div>

              <div
                v-if="checking.Annexes.IsEmpty"
                class="fst-italic text-muted ms-3"
              >
                {{ $tcust('checking.annexes.empty') }}
              </div>
              <div
                v-else
                class="ms-3"
              >
                <div
                  v-for="(value, key) of checking.Annexes"
                  :key="key"
                >
                  <div v-if="key !== 'IsEmpty' && value">
                    {{ $tcust(`checking.annexes.${key.charAt(0).toLowerCase() + key.slice(1, -6)}`, { n: value || 0 }) }}
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </CardContainer>
      </div>

      <div class="custom-size w-100 m-2">
        <Accommodation
          :accommodation="checking.Accomodation.Accomodation"
          :showAppt="false"
          :appearance="'Card'"
          :forced="true"
        />
      </div>

      <CardContainer class="bg-secondary custom-size m-2">
        <div class="CheckingView-flex bg-box d-flex flex-row justify-content-center flex-wrap w-100 pt-3">
          <!-- Owner -->
          <div
            class="d-flex flex-column w-fit pb-3"
          >
            <div class="fst-italic text-muted">
              {{ $tcust('checking.owner') }}
            </div>
            <div
              v-if="checking.Owner"
              class="px-sm-3"
            >
              <ProfilInfos
                :key="checking.Owner"
                :person="checking.Owner"
                class="custom-card border border-current rounded-5 mt-2 overflow-hidden"
              />
            </div>
            <div v-else class="text-muted fst-italic m-2">
              {{ $tcust('unknow') }}
            </div>
          </div>

          <!-- Occupants -->
          <div
            class="d-flex flex-column w-fit pb-3"
          >
            <div class="fst-italic text-muted">
              {{ $tcust('checking.occupant', { n: checking.Occupants }) }}
            </div>
            <div
              v-for="(occupant, index) of checking.Occupants"
              :key="index"
            >
              <div
                v-if="occupant.Person"
                class="px-sm-3"
              >
                <ProfilInfos
                  :key="occupant.Person"
                  :person="occupant.Person"
                  class="custom-card border border-current rounded-5 mt-2 overflow-hidden"
                />
              </div>
              <div v-else class="text-muted fst-italic m-2">
                {{ $tcust('unknow') }}
              </div>
            </div>
          </div>
        </div>
      </CardContainer>

      <CardContainer class="bg-secondary custom-size m-2">
        <div class="CheckingView-flex bg-box d-flex flex-row justify-content-center flex-wrap w-100 pt-3">
          <!-- Representatives -->
          <div
            class="d-flex flex-column w-fit pb-3"
          >
            <span class="fst-italic text-muted ms-0">
              {{ $tcust('checking.representative', { n: checking.Representatives.Child ? 2 : 1 }) }}
            </span>
            <div v-if="checking.Representatives.Current">
              <div
                class="px-sm-3"
              >
                <ProfilInfos
                  :key="checking.Representatives.Current"
                  :person="checking.Representatives.Current"
                  class="custom-card border border-current rounded-5 mt-2 overflow-hidden"
                />
              </div>
              <div
                v-if="checking.Representatives.Child"
                class="px-sm-3"
              >
                <ProfilInfos
                  :key="checking.Representatives.Child.Current"
                  :person="checking.Representatives.Child.Current"
                  class="custom-card border border-current rounded-5 mt-2 overflow-hidden"
                />
              </div>
            </div>
            <div v-else class="text-muted fst-italic m-2">
              {{ $tcust('unknow') }}
            </div>
          </div>

          <!-- Experts -->
          <div
            class="d-flex flex-column w-fit pb-3"
          >
            <div class="fst-italic text-muted">
              {{ $tcust('checking.expert', { n: checking.Expert }) }}
            </div>
              <div
                v-if="checking.Expert"
                class="px-sm-3"
              >
                <ProfilInfos
                :key="checking.Expert"
                :person="checking.Expert"
                class="custom-card border border-current rounded-5 mt-2 overflow-hidden"
                />
            </div>
            <div v-else class="text-muted fst-italic m-2">
              {{ $tcust('unknow') }}
            </div>
          </div>
        </div>
      </CardContainer>
    </div>

    <Loader v-else />
  </div>
</template>

<script setup>
import Accommodation from "@/components/summary/accommodation/Accommodation";

import Setup from "./CheckingViewSetup";
const { checking, loaded } = Setup();
</script>

<style lang="scss" scoped>
.custom-box {
  max-width: 95vw;
  max-height: 300px;
}
.custom-card {
  max-width: 100%;
  min-width: 240px;
}
.custom-text {
  letter-spacing: 1px;
}
.custom-size {
  // xs
  @media (max-width: 575.98px) {
    width: 100% !important;
    &.custom-size {
      width: 320px !important;
    }
  }
  // sm
  @media (max-width: 767.98px) {
    width: 75% !important;
  }
  // md
  @media (min-width: 768px) {
    width: 45% !important;
  }
  // lg +
}
.CheckingView-flex > * {
  min-width: 50%;
}
</style>
